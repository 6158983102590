import React from 'react'
import { graphql } from 'gatsby'
import Link from 'gatsby-link'
import { GatsbyImage } from "gatsby-plugin-image"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronRight } from '@fortawesome/pro-regular-svg-icons'
import { MDBRow, MDBCol, MDBContainer } from 'mdbreact'
import Layout from '../components/layout'
import Hero from '../components/heroSubpage'
import SEO from '../components/seo'

const SupportTemplate = ({ data }) => {
  const post = data.markdownRemark
  return (
    <div id="sub-page">
      <Layout>
        <Hero
          class="intro-65"
          image={post?.frontmatter?.image?.childImageSharp?.gatsbyImageData}
          foreimage={null}
          alt={post.frontmatter.alttext}
          title={post.frontmatter.title}
          subtitle={post.frontmatter.subtitle}
          type="root"
        />

        <section className="bg-white">
          <MDBContainer>
            <MDBRow>
              <MDBCol>
              <div
                  className="font-w-400 text-xs-extra-large divlink text-opening-para"
                  dangerouslySetInnerHTML={{__html: post.frontmatter.openingpara}}
                />
                <div
                  className="mt-3 richtext divlink"
                  dangerouslySetInnerHTML={{ __html: post.html }}
                />
              </MDBCol>
            </MDBRow>
          </MDBContainer>
        </section>

        {post.frontmatter.section && (
          <section className="bg-light-blue">
            <MDBContainer>
              <MDBRow>
                <MDBCol md="12" className="pb-4">
                  <h2 className="font-alt font-w-700 letter-spacing-1 title-xs-medium title-large">
                    Customer Portals
                  </h2>
                </MDBCol>
              </MDBRow>
              <MDBRow>
                <>
                  {post.frontmatter.section.map((sections, index) => {
                    return (
                      <div key={index}>
                        <MDBCol lg="12" className="mt-3 mb-3">
                          <h3 className="font-alt font-w-700 letter-spacing-1 title-xs-medium title-medium pb-3">
                            {sections.title}
                          </h3>
                        </MDBCol>

                        <MDBCol lg="12">
                          <MDBRow className="pb-4">
                            {sections.products.map((product, index) => (
                              <MDBCol md="6" lg="4" className="pb-30 item-prods" key={index}>
                                <div className="card promoting-card flex-fill">
                                  <div
                                    className="card-body d-flex flex-row p-4"
                                    style={{
                                      minHeight: '170px',
                                      maxHeight: '170px',
                                    }}
                                  >
                                    <div
                                      style={{
                                        minWidth: '75px',
                                        maxWidth: '75px',
                                      }}
                                    >
                                      <GatsbyImage
                                        image={product.image.childImageSharp.gatsbyImageData}
                                        className="mr-1"
                                        alt={product.alttext}
                                      />
                                    </div>
                                    <div>
                                      <p className="text-medium text-xs-large text-large font-w-700">
                                        {product.title}
                                      </p>
                                      <p className="text-medium text-xs-small text-gray-dark">
                                        {product.description}
                                      </p>
                                    </div>
                                  </div>
                                  <div className="card-footer">
                                    {product.link !== null && (
                                      <div className="text-center">
                                        {product.link.charAt(0) == '/' ? (
                                          <Link
                                            to={product.link}
                                            className="btn btn-block btn-md btn-mdb-color"
                                          >
                                            <FontAwesomeIcon
                                              icon={faChevronRight}
                                              className="mr-2"
                                            />
                                            {product.linktext}
                                          </Link>
                                        ) : (
                                          <a
                                            href={product.link}
                                            className="btn btn-block btn-md btn-mdb-color"
                                            style={{color: "#fff"}}
                                            target="_blank"
                                            rel="noopener"
                                          >
                                            <FontAwesomeIcon
                                              icon={faChevronRight}
                                              className="mr-2"
                                            />
                                            {product.linktext}
                                          </a>
                                        )}
                                     </div>
                                    )}
                                  </div>
                                </div>
                              </MDBCol>
                            ))}
                          </MDBRow>
                        </MDBCol>
                      </div>
                    )
                  })}
                </>
              </MDBRow>
            </MDBContainer>
          </section>
        )}
      </Layout>
    </div>
  )
}
export default SupportTemplate

export function Head({ data, location }) {
  const post = data.markdownRemark
  return (
    <SEO
      title={post.frontmatter.meta.title}
      description={post.frontmatter.meta.description}
      url={location.pathname} 
      image={post.frontmatter.image.childImageSharp?.gatsbyImageData?.images?.fallback?.src}          
      canonical={location.pathname}                  
    />
  )
}

export const query = graphql`
  query($slug: String!) {  
    markdownRemark(
      fields: { slug: { eq: $slug } }
      frontmatter: { template: { eq: "support" } }
    ) {
      frontmatter {
        meta {
          title
          description 
        }        
        title
        subtitle
        openingpara
        alttext
        image {
          childImageSharp {
            gatsbyImageData(quality: 90) 
          }
        }
        section {
          title
          products {
            title
            image {
          childImageSharp {
            gatsbyImageData(width:50, height: 50, quality: 90) 
          }
        }
            alttext
            description
            linktext
            link
          }
        }
      }
      html
    }
  }
`
